<script setup lang="ts">
import homeIcon from '@/assets/svg/24/bar-home.svg';
import historyIcon from '@/assets/svg/24/bar-history.svg';
import qrIcon from '@/assets/svg/24/qr.svg';
import browserIcon from '@/assets/svg/24/bar-browser.svg';
import profileIcon from '@/assets/svg/24/bar-profile.svg';
import type { NavBar } from './MNavBar.const';

const route = useRoute();

/**
 * Пункты нижнего меню
 */
const items = [
  { path: '/home', label: 'Главная', icon: homeIcon },
  { path: '/history', label: 'История', icon: historyIcon },
  { path: '/camera', isCenter: true, icon: qrIcon },
  { path: '/browser', label: 'Браузер', icon: browserIcon },
  { path: '/profile', label: 'Профиль', icon: profileIcon },
];

/**
 * Пункты с выделением активного
 */
const navItems = computed<NavBar[]>(() =>
  items.map((one) => ({
    icon: one.icon,
    to: one.path,
    label: one.label,
    isCenter: one.isCenter,
    isActive: route.path === one.path,
  })),
);
</script>

<template>
  <nav class="m-nav-bar">
    <div class="container">
      <ul class="row">
        <li v-for="item in navItems" :key="item.to" class="item">
          <ANavBarButton
            :icon="item.icon"
            :label="item.label"
            :to="item.to"
            :is-center="item.isCenter"
            :is-active="item.isActive"
            @click="item.click"
          />
        </li>
      </ul>
    </div>
  </nav>
</template>

<style lang="scss">
.m-nav-bar {
  background: #fff;
  padding: 10px 0;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
  }
}
</style>
